<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="ProductTable"/>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-0 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-sheet class="dense-inputs d-flex flex-row">
                        <div class="success pt-0 pb-3 px-2 lighten-1 border-rad-sm col-lg-2 col-xs-4 col-md-3" style="overflow-y: auto" :style="'height: ' + listHeight + 'px'">
                            <v-row no-gutters class="d-flex flex-row align-items-center pt-3">
                                <v-switch
                                    :false-value="0"
                                    :label="searchByProperty ? $t('message.filterByProperty') : $t('message.filterBySupplier')"
                                    :true-value="1"
                                    class="mt-0 font-sm"
                                    color="primary"
                                    dense
                                    flat
                                    hide-details="auto"
                                    inset
                                    v-model="searchByProperty"
                                    @change="changeFilter"
                                />
                                <v-spacer/>
                                <v-btn small class="grey white--text darken--text px-2" @click="resetPropertyOptions" v-if="searchByProperty == 1">{{ $t('message.clearAll') }}</v-btn>
                            </v-row>
                            <template v-if="searchByProperty == 1">
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.productGroup"
                                        :loading="loading.options.productGroup"
                                        :placeholder="$t('message.product')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.productGroup"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.designProfile"
                                        :loading="loading.options.designProfile"
                                        :placeholder="$t('message.designProfile')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.designProfile"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.species"
                                        :loading="loading.options.species"
                                        :placeholder="$t('message.species')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.species"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.density"
                                        :loading="loading.options.density"
                                        :placeholder="$t('message.density')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.density"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.composition"
                                        :loading="loading.options.composition"
                                        :placeholder="$t('message.composition')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.composition"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.glue"
                                        :loading="loading.options.glue"
                                        :placeholder="$t('message.glue')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.glue"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.surface"
                                        :loading="loading.options.surface"
                                        :placeholder="$t('message.surfaceEdges')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.surface"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.finishing"
                                        :loading="loading.options.finishing"
                                        :placeholder="$t('message.finishing')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.finishing"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.grade"
                                        :loading="loading.options.grade"
                                        :placeholder="$t('message.grades')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.grade"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.moistureContent"
                                        :loading="loading.options.moistureContent"
                                        :placeholder="$t('message.mc')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.moistureContent"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.originCertification"
                                        :loading="loading.options.originCertification"
                                        :placeholder="$t('message.originCertification')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.originCertification"
                                        @change="filterList()"
                                    />
                                </v-row>
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.marketCertification"
                                        :loading="loading.options.marketCertification"
                                        :placeholder="$t('message.marketCertification')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.marketCertification"
                                        @change="filterList()"
                                    />
                                </v-row>
                            </template>
                            <template v-if="searchByProperty == 0">
                                <v-row no-gutters class="pt-3">
                                    <v-autocomplete
                                        :items="filterOptions.supplier"
                                        :loading="loading.options.supplier"
                                        :placeholder="$t('message.supplier')"
                                        autocomplete="off"
                                        class="grey lighten-4"
                                        clearable
                                        dense
                                        hide-details
                                        loader-height="5"
                                        solo
                                        v-model="filterValues.supplier"
                                        @change="filterList()"
                                    />
                                </v-row>
                            </template>
                        </div>
                        <div class="pt-2 pb-3 px-2 ml-3 pr-5 lighten-1 border-rad-sm col-lg-10 col-xs-8 col-md-9" :style="'height: ' + listHeight + 'px'">
                            <div style="position: relative">
                                <v-overlay
                                    :value="loading.items"
                                    absolute
                                    opacity="0.15"
                                >
                                    <v-row>
                                        <v-col class="text-center">
                                            <v-progress-circular
                                                color="primary"
                                                indeterminate
                                                size="40"
                                                width="6"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-overlay>
                                <div class="d-flex flex-row align-center">
                                    <v-btn icon class="ml-0" @click="filterList(); loading.reload = true" :loading="loading.reload"><v-icon large>refresh</v-icon></v-btn>
                                    <v-spacer/>
                                    <ExportTableProduct
                                        :export-conditions="{}"
                                        :export-fields="exportFields"
                                        :export-data="allProducts"
                                        :export-source="'supplier-product-search'"
                                        class="float-right"
                                        style="padding-top: 0px !important;"
                                    />
                                </div>
                                <SupplierProductSearchList
                                    :item-list="allProducts"
                                    :list-height="listHeight - ($vuetify.breakpoint.smAndDown ? 18 : 58)"
                                    :key-words.sync="keyWords"
                                    class="mt-3"
                                    @export-fields-loaded="setExportFields($event)"
                                />
                            </div>
                        </div>
                    </v-sheet>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { api } from 'Api';
import _ from 'lodash'
const SupplierProductSearchList = () => import('Components/Appic/SupplierProductSearchList')
const ExportTableProduct = () => import("Components/Appic/ExportTableProduct");

export default {
    name: 'SupplierProductSearchV3',
    components: {ExportTableProduct, SupplierProductSearchList},
    data() {
        return {
            exportFields: [],
            filterOptions: {
                composition: [],
                density: [],
                designProfile: [],
                edge: [],
                finishing: [],
                glue: [],
                grade: [],
                marketCertification: [],
                moistureContent: [],
                originCertification: [],
                productGroup: [],
                species: [],
                supplier: [],
                surface: []
            },
            filterValues: {
                composition: null,
                density: null,
                designProfile: null,
                edge: null,
                finishing: null,
                glue: null,
                grade: null,
                length: null,
                marketCertification: null,
                moistureContent: null,
                originCertification: null,
                productGroup: null,
                species: null,
                supplier: null,
                surface: null,
                thickness: null,
                width: null
            },
            keyWords: [],
            allProducts: [],
            listHeight: '500',
            loader: false,
            loading: {
                items: false,
                options: {
                    composition: false,
                    density: false,
                    designProfile: false,
                    // dryingType: false,
                    edge:  false,
                    finishing: false,
                    glue: false,
                    grade: false,
                    length: false,
                    marketCertification: false,
                    moistureContent: false,
                    originCertification: false,
                    productGroup: false,
                    species: false,
                    supplier: false,
                    surface: false,
                    thickness: false,
                    width: false
                },
                reload: false
            },
            propertiesToLoad: [
                'product_group',
                'design_profile',
                'species',
                'grade',
                'origin_certification',
                'market_certification',
                'moisture_content',
                'density',
                'surface',
                'composition',
                'glue',
                'finishing',
                'supplier'
            ],
            searchByProperty: 1
        }
    },
    methods: {
        changeFilter() {
            this.$nextTick(() => {
                this.allProducts = [];
                if(this.searchByProperty == 1){
                    this.filterValues.supplier = null
                } else {
                    //search by supplier
                    this.filterValues.productGroup = null
                    this.filterValues.designProfile = null
                    this.filterValues.species = null
                    this.filterValues.grade = null
                    this.filterValues.originCertification = null
                    this.filterValues.marketCertification = null
                    this.filterValues.moistureContent = null
                    this.filterValues.density = null
                    this.filterValues.surface = null
                    this.filterValues.composition = null
                    this.filterValues.glue = null
                    this.filterValues.finishing = null
                    this.filterValues.edge = null
                    this.filterValues.width = null
                    this.filterValues.thickness = null
                    this.filterValues.length = null
                }
            })
        },
        filterList() {
            this.loading.items = true
            let conditions = []
            for(let i = 0; i < this.propertiesToLoad.length; i++){
                const newKey = _.camelCase(this.propertiesToLoad[i])
                if(this.filterValues[newKey] != null){
                    const condition = {
                        field: this.propertiesToLoad[i],
                        value: this.filterValues[newKey]
                    }
                    conditions.push(condition)
                }
            }
            api
                .get('/products/suppliers', {
                    params: {
                        conditions: conditions
                    }
                })
                .then((response) => {
                    if(response.data.status == 'success'){
                        this.allProducts = response.data.data.products
                        this.keyWords = response.data.data.keywords
                    } else {
                        this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.items = false
                    if(this.loading.reload) this.loading.reload = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.listCannotBeLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.items = false
                    if(this.loading.reload) this.loading.reload = false
                })
            return true
        },
        filterListBySupplier() {
            return true
        },
        handleResize() {
            this.listHeight = window.innerHeight - (100);
            return true
        },
        async loadFilterOptions() {
            for(let i = 0; i < this.propertiesToLoad.length; i++){
                const newKey = _.camelCase(this.propertiesToLoad[i])
                this.turnOnLoadingIndicator(newKey)
            }
            api
                .get('/products/property-options', {
                    params: {
                        properties: this.propertiesToLoad
                    }
                })
                .then((result) => {
                    if(result.data.status == 'success'){
                        const options = result.data.data.options
                        this.filterOptions.composition = options['composition']
                        this.filterOptions.density = options['density']
                        this.filterOptions.designProfile = options['design_profile']
                        this.filterOptions.edge = options['edgs']
                        this.filterOptions.finishing = options['finishing']
                        this.filterOptions.glue = options['glue']
                        this.filterOptions.grade = options['grade']
                        this.filterOptions.marketCertification = options['market_certification']
                        this.filterOptions.moistureContent = options['moisture_content']
                        this.filterOptions.originCertification = options['origin_certification']
                        this.filterOptions.productGroup = options['product_group']
                        this.filterOptions.species = options['species']
                        this.filterOptions.supplier = options['supplier']
                        this.filterOptions.surface = options['surface']
                    } else {
                        this.$toast.error(this.$t('message.errors.optionsNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    for(let i = 0; i < this.propertiesToLoad.length; i++){
                        const newKey = _.camelCase(this.propertiesToLoad[i])
                        this.turnOffLoadingIndicator(newKey)
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.optionsNotLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    for(let i = 0; i < this.propertiesToLoad.length; i++){
                        const newKey = _.camelCase(this.propertiesToLoad[i])
                        this.turnOffLoadingIndicator(newKey)
                    }
                })
        },
        resetPropertyOptions() {
            this.allProducts = []
            this.filterValues.productGroup = null
            this.filterValues.designProfile = null
            this.filterValues.species = null
            this.filterValues.grade = null
            this.filterValues.originCertification = null
            this.filterValues.marketCertification = null
            this.filterValues.moistureContent = null
            this.filterValues.density = null
            this.filterValues.surface = null
            this.filterValues.composition = null
            this.filterValues.glue = null
            this.filterValues.finishing = null
            this.filterValues.edge = null
            this.filterValues.width = null
            this.filterValues.thickness = null
            this.filterValues.length = null
        },
        setExportFields(exportFields) {
            this.exportFields = exportFields
        },
        turnOnLoadingIndicator (property) {
            this.loading.options[property] = 'warning'
            return true
        },
        turnOffLoadingIndicator (property){
            this.loading.options[property] = false
            return true
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.loadFilterOptions()
    },
    mounted() {
        this.handleResize()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style scoped>

</style>